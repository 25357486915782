
import {
  Component,
  Vue
} from 'vue-property-decorator'
import {
  AuthModule
} from '@/store/modules/AuthModule'
  @Component({
    name: 'Dispatch'
  })
export default class Dispatch extends Vue {
  get board () {
    if (AuthModule.permissions.includes('op@office')) {
      return () => import('./components/Office.vue')
    }

    if (AuthModule.permissions.includes('op@barge')) {
      return () => import('./components/Barge.vue')
    }

    if (AuthModule.permissions.includes('op@slpa')) {
      return () => import('./components/SLPA.vue')
    }

    if (AuthModule.permissions.includes('op@delivery')) {
      return () => import('./components/Delivery.vue')
    }
  }
}

